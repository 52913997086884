<template>
  <div>
    <div class="banner service-banner"><div class="banner-item">{{item}}</div></div>
    <div class="container">
      <div class="service">
        <div class="service-title-sm item-color">流量</div>
        <div class="service-title">地方垃圾分类的联营合作服务</div>
        <div class="service-p">
          <p>
            设备所入驻的小区地点皆为居民区流量大、曝光率高的中心区域、主路、广场活动中心等，广告智能触摸屏
          </p>
          <p>
            每天会与用户发生高频的人机交互、广告屏可提供影音、图片等多种形式的广告合作业务。
          </p>
        </div>
        <div class="service-img"><img src="../../assets/img/service/service-1.png" alt="" /></div>
      </div>

      <div class="service">
        <div class="service-title-sm item-color">产品</div>
        <div class="service-title">政府一体化垃圾分类解决服务</div>
        <div class="service-p">
          <p>
            与传统垃圾垃圾桶相比，小松鼠智能垃圾分类回收机在外观上更加时尚、美观和洁净，且软件界面上十分友好，便于上手。
          </p>
          <p>
            老百姓们无处安放的生活垃圾、闲置物品都可以通过小松鼠平台变成真金白银，既环保又赚钱！
          </p>
        </div>
        <div class="service-img"><img src="../../assets/img/service-2.png" alt="" /></div>
      </div>

      <div class="service">
        <div class="service-title-sm item-color">开发</div>
        <div class="service-title">系统开发服务</div>
        <div class="service-p">
          <p>
            可以通过系统平台，辖区内各居委会、物业、企业及事业单位完成每日的数据上报，完成居民实时
          </p>
          <p>
            管控，完成居民垃圾投递积分统计、统计周期、统计对象、统计内容等，直接由系统输出，不再需要繁琐的询问以及人工搜集制
          </p>
          <p>
            单。所有数据行成大数据管控平台，在视觉化、数据化、流程化方面为政府提供强有力的把控。
          </p>
        </div>
        <div class="service-img"><img src="../../assets/img/service/service-3.png" alt="" /></div>
      </div>

      <div class="service">
        <div class="service-title-sm item-color">引流</div>
        <div class="service-title">物联网的合作服务</div>
        <div class="service-p">
          <p>• 嫁接新零售</p>
          <p>• 广告服务</p>
          <p>• 互联网引流服务</p>
        </div>
        <div class="service-img"><img src="../../assets/img/service/service-4.png" alt="" /></div>
      </div>

     
    </div>
     <div class="service">
        <div class="service-title-sm item-color">售后</div>
        <div class="service-title">运输安装、售后负责</div>
        <div class="service-p">
          <p>个性化定制服务流程</p>
        </div>
        <div class="service-img"><img src="../../assets/img/service/service-5.png" alt="" /></div>
      </div>
      <div style="height:169px;"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item:'服务'
    };
  },

  mounted: function () {},
  methods: {},
};
</script>

<style>
.service-banner{
  background-image: url('../../assets/img/service/service-banner.png');
}
</style>